exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kinderen-js": () => import("./../../../src/pages/kinderen.js" /* webpackChunkName: "component---src-pages-kinderen-js" */),
  "component---src-pages-levend-water-js": () => import("./../../../src/pages/levend-water.js" /* webpackChunkName: "component---src-pages-levend-water-js" */),
  "component---src-pages-over-de-stichting-js": () => import("./../../../src/pages/over-de-stichting.js" /* webpackChunkName: "component---src-pages-over-de-stichting-js" */),
  "component---src-pages-tieners-js": () => import("./../../../src/pages/tieners.js" /* webpackChunkName: "component---src-pages-tieners-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

